@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/settings/clubs.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/settings/colours.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/settings/layout.scss";
@import "settings/colours.scss";
@import "settings/layout.scss";

@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/tools/general.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/tools/grid.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/tools/hamburger.scss";
@import "/buddy/frame-boilerplate/site/content/themes/common/assets/styles/tools/themes.scss";
@import "tools/themes.scss";


@each $club, $colors in $themes {

  body.t-#{$club} {
	  background-color: map-get($colors, primary);

	h1 a {
	  background-image: url("/content/themes/common/assets/images/club-logos/#{$club}.svg");
	  background-size: contain;
	  width: 200px;
	  height: 200px;
	}


	#nav a {
	  color: $c-white;
	}

	#backtoblog a {
	  color: $c-white;
	}

	.button.button-primary {
	  background: map-get($colors, primary);
	  border-color: map-get($colors, primary);
		color: map-get($colors, highlight);
	  border-radius: 0;
	  text-shadow: none;
	  box-shadow: none;

	  &:hover,
	  &:focus {
		@include shadowed();
	  }

	}

  }

}
