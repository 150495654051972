body.t-NWD {
  background-color: #001a49;
}
body.t-NWD h1 a {
  background-image: url("/content/themes/common/assets/images/club-logos/NWD.svg");
  background-size: contain;
  width: 200px;
  height: 200px;
}
body.t-NWD #nav a {
  color: #fff;
}
body.t-NWD #backtoblog a {
  color: #fff;
}
body.t-NWD .button.button-primary {
  background: #001a49;
  border-color: #001a49;
  color: #ed174b;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none;
}
body.t-NWD .button.button-primary:hover, body.t-NWD .button.button-primary:focus {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}

body.t-WEST {
  background-color: #221e20;
}
body.t-WEST h1 a {
  background-image: url("/content/themes/common/assets/images/club-logos/WEST.svg");
  background-size: contain;
  width: 200px;
  height: 200px;
}
body.t-WEST #nav a {
  color: #fff;
}
body.t-WEST #backtoblog a {
  color: #fff;
}
body.t-WEST .button.button-primary {
  background: #221e20;
  border-color: #221e20;
  color: #de0532;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none;
}
body.t-WEST .button.button-primary:hover, body.t-WEST .button.button-primary:focus {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}

body.t-TIGERS {
  background-color: #201c20;
}
body.t-TIGERS h1 a {
  background-image: url("/content/themes/common/assets/images/club-logos/TIGERS.svg");
  background-size: contain;
  width: 200px;
  height: 200px;
}
body.t-TIGERS #nav a {
  color: #fff;
}
body.t-TIGERS #backtoblog a {
  color: #fff;
}
body.t-TIGERS .button.button-primary {
  background: #201c20;
  border-color: #201c20;
  color: #facd01;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none;
}
body.t-TIGERS .button.button-primary:hover, body.t-TIGERS .button.button-primary:focus {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}

body.t-CNTRL {
  background-color: #072e9d;
}
body.t-CNTRL h1 a {
  background-image: url("/content/themes/common/assets/images/club-logos/CNTRL.svg");
  background-size: contain;
  width: 200px;
  height: 200px;
}
body.t-CNTRL #nav a {
  color: #fff;
}
body.t-CNTRL #backtoblog a {
  color: #fff;
}
body.t-CNTRL .button.button-primary {
  background: #072e9d;
  border-color: #072e9d;
  color: #ffffff;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none;
}
body.t-CNTRL .button.button-primary:hover, body.t-CNTRL .button.button-primary:focus {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}

body.t-EAGLES {
  background-color: #02573d;
}
body.t-EAGLES h1 a {
  background-image: url("/content/themes/common/assets/images/club-logos/EAGLES.svg");
  background-size: contain;
  width: 200px;
  height: 200px;
}
body.t-EAGLES #nav a {
  color: #fff;
}
body.t-EAGLES #backtoblog a {
  color: #fff;
}
body.t-EAGLES .button.button-primary {
  background: #02573d;
  border-color: #02573d;
  color: #ffd300;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none;
}
body.t-EAGLES .button.button-primary:hover, body.t-EAGLES .button.button-primary:focus {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}

body.t-NORTH {
  background-color: #e20a16;
}
body.t-NORTH h1 a {
  background-image: url("/content/themes/common/assets/images/club-logos/NORTH.svg");
  background-size: contain;
  width: 200px;
  height: 200px;
}
body.t-NORTH #nav a {
  color: #fff;
}
body.t-NORTH #backtoblog a {
  color: #fff;
}
body.t-NORTH .button.button-primary {
  background: #e20a16;
  border-color: #e20a16;
  color: #ffffff;
  border-radius: 0;
  text-shadow: none;
  box-shadow: none;
}
body.t-NORTH .button.button-primary:hover, body.t-NORTH .button.button-primary:focus {
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}