
@mixin space-horizontally( $space:1rem ) {
	> *:not(:last-child) {
		margin-right: #{$space};
	}
}

@mixin space-vertically( $space:1rem ) {
	> *:not(:last-child) {
		margin-bottom: #{$space};
	}
}

@mixin clearfix {
	&::after {
		clear: both;
		content: "";
		display: table;
	}
}

@mixin truncate {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin visually-hidden {
	position: absolute !important;
	height: 1px; width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

@mixin shadowed {
	box-shadow: 0 1px 20px 0 rgba( $c-black, 0.1 );
}

@mixin arrow {
	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		height: 65%;
		width: 2px;
		border-radius: 2px;
		background-color: currentColor;
		transform-origin: 50% 100%;
	}

	&:before {
		transform: translate( -50%, -65%) rotate(45deg);
		transform-origin: 0 100%;
	}

	&:after {
		transform: translate( -50%, -65%) rotate(-45deg);
		transform-origin: 100% 100%;
	}
}